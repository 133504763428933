var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserAward_wrapper" },
    [
      _c(
        "div",
        { staticClass: "select_wrap" },
        [
          _c(
            "div",
            [
              _c("span", [_vm._v("请输入ID：")]),
              _c("el-input", {
                staticClass: "ml10",
                attrs: { clearable: true, placeholder: "请输入ID搜索 " },
                model: {
                  value: _vm.EconnoisseurInfo.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.EconnoisseurInfo, "userId", $$v)
                  },
                  expression: "EconnoisseurInfo.userId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("span", [_vm._v("请输入昵称：")]),
              _c("el-input", {
                staticClass: "ml10",
                attrs: { clearable: true, placeholder: "请输入用户昵称搜索 " },
                model: {
                  value: _vm.EconnoisseurInfo.nickname,
                  callback: function($$v) {
                    _vm.$set(_vm.EconnoisseurInfo, "nickname", $$v)
                  },
                  expression: "EconnoisseurInfo.nickname"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("span", [_vm._v("请输入员工：")]),
              _c("el-input", {
                staticClass: "ml10",
                attrs: { clearable: true, placeholder: "请输入员工名称搜索 " },
                model: {
                  value: _vm.EconnoisseurInfo.kpiUserName,
                  callback: function($$v) {
                    _vm.$set(_vm.EconnoisseurInfo, "kpiUserName", $$v)
                  },
                  expression: "EconnoisseurInfo.kpiUserName"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.searchPage }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "ml10",
              attrs: { type: "success", icon: "el-icon-download" },
              on: { click: _vm.exportData }
            },
            [_vm._v("导出表格")]
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "mt20",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.EconnoisseurList, border: "", stripe: "" }
        },
        [
          _c("el-table-column", { attrs: { prop: "userId", label: "用户ID" } }),
          _c("el-table-column", {
            attrs: { prop: "nickname", label: "用户昵称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "happenTime", label: "进入时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "bsUserId", label: "邀请人ID" }
          }),
          _c("el-table-column", {
            attrs: { prop: "bsNickname", label: "邀请人昵称" }
          }),
          _c("el-table-column", {
            attrs: { prop: "kpiUserName", label: "归属员工" }
          }),
          _c("el-table-column", {
            attrs: { prop: "brandName", label: "归属品牌" }
          }),
          _c("el-table-column", {
            attrs: { prop: "shopName", label: "归属门店" }
          }),
          _c("el-table-column", {
            attrs: { prop: "remark", label: "备注" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    scope.row.remark == "normal"
                      ? _c("el-tag", { attrs: { type: "primary" } }, [
                          _vm._v("正常")
                        ])
                      : _vm._e(),
                    scope.row.remark == "second"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("超出每秒预设的阈值")
                        ])
                      : _vm._e(),
                    scope.row.remark == "hour"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("超出每小时预设的阈值")
                        ])
                      : _vm._e(),
                    scope.row.remark == "day"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("超出每天预设的阈值")
                        ])
                      : _vm._e(),
                    scope.row.remark == "qcloud"
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("腾讯云判断异常")
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.EconnoisseurInfo.page,
              "page-size": 10,
              layout: "total, prev, pager, next, jumper",
              total: _vm.EconnoisseurTotal
            },
            on: { "current-change": _vm.handleCurrentChange }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }