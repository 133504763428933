<template>
  <div class="UserAward_wrapper">
    <div class="select_wrap">
      <div>
        <span>请输入ID：</span>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入ID搜索 "
          v-model="EconnoisseurInfo.userId"
        ></el-input>
      </div>
      <div>
        <span>请输入昵称：</span>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入用户昵称搜索 "
          v-model="EconnoisseurInfo.nickname"
        ></el-input>
      </div>
      <div>
        <span>请输入员工：</span>
        <el-input
          class="ml10"
          :clearable="true"
          placeholder="请输入员工名称搜索 "
          v-model="EconnoisseurInfo.kpiUserName"
        ></el-input>
      </div>
      <el-button type="primary" class="ml10" icon="el-icon-search" @click="searchPage">搜索</el-button>
      <el-button type="success" class="ml10" icon="el-icon-download" @click="exportData">导出表格</el-button>
    </div>
    <el-table class="mt20" :data="EconnoisseurList" border stripe style="width: 100%">
      <el-table-column prop="userId" label="用户ID"></el-table-column>
      <el-table-column prop="nickname" label="用户昵称"></el-table-column>
      <el-table-column prop="happenTime" label="进入时间"></el-table-column>
      <el-table-column prop="bsUserId" label="邀请人ID"></el-table-column>
      <el-table-column prop="bsNickname" label="邀请人昵称"></el-table-column>
      <el-table-column prop="kpiUserName" label="归属员工"></el-table-column>
      <el-table-column prop="brandName" label="归属品牌"></el-table-column>
      <el-table-column prop="shopName" label="归属门店"></el-table-column>
      <el-table-column prop="remark" label="备注">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.remark=='normal'">正常</el-tag>
          <el-tag type="warning" v-if="scope.row.remark=='second'">超出每秒预设的阈值</el-tag>
          <el-tag type="warning" v-if="scope.row.remark=='hour'">超出每小时预设的阈值</el-tag>
          <el-tag type="warning" v-if="scope.row.remark=='day'">超出每天预设的阈值</el-tag>
          <el-tag type="warning" v-if="scope.row.remark=='qcloud'">腾讯云判断异常</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <div class="tab-page">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        :current-page="EconnoisseurInfo.page"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="EconnoisseurTotal"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import StatisticsAjax from "@/utils/https/modules/Statistics.request.js";
export default {
  name: "AbNormal", // 数据统计-营销数据-羊毛党记录
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      idInput: "",
      options: [
        { value: "normal", label: "正常" },
        { value: "second", label: "超出每秒预设的阈值" },
        { value: "hour", label: "超出每小时预设的阈值" },
        { value: "day", label: "超出每天预设的阈值" },
        { value: "qcloud", label: "腾讯云判断异常" },
      ],
      value: "",
      EconnoisseurInfo: {
        actId: 0,
        kpiUserName: "",
        nickname: "",
        page: 1,
        size: 10,
        userId: "",
      },
      EconnoisseurList: [],
      EconnoisseurTotal: 0,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 羊毛党列表页码请求
    handleCurrentChange(val) {
      this.EconnoisseurInfo.page = val;
      this.getQueryEconnoisseurList();
    },
    searchPage() {
      this.EconnoisseurInfo.page = 1;
      this.getQueryEconnoisseurList();
    },
    // 查询羊毛党列表
    async getQueryEconnoisseurList() {
      try {
        this.EconnoisseurInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await StatisticsAjax.getQueryEconnoisseurList(this.EconnoisseurInfo);
        this.EconnoisseurList = list;
        this.EconnoisseurTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出羊毛党列表
    async exportData() {
      try {
        const data = await StatisticsAjax.getExportEconnoisseurList(
          this.EconnoisseurInfo
        );
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "羊毛党统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryEconnoisseurList();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.UserAward_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    div {
      height: 40px;
      line-height: 40px;
      margin: 0 10px;
      .el-input {
        width: 200px;
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    // text-align: right;
    margin: 10px 0;
  }
}
</style>
